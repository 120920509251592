import React from "react";

const chat = React.lazy(() => import("../pages/Chat"));
const notFound = React.lazy(() => import("../pages/NotFound"));

export const publicRoutes = [
  { path: "/notfound", component: notFound },

  { path: "/", component: chat },
];

export const PrivateRouter = [
];
