import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRouter, publicRoutes } from "../src/routes";
import ProviderRouter from "./ProviderRouter";

const App = () => {
	return (
		// <div >
		// 	<Chat />
		// </div>


		<Router>
          <Routes>
            
            <Route>
              {publicRoutes.map((route, index) => {
                const Page = route.component;
                return (
                  <Route key={index}>
                    <Route path={route.path} element={<Page />} />
                  </Route>
                );
              })}
              {PrivateRouter.map((route, index) => {
                const Page = route.component;
                return (
                  <Route key={index}>
                    <Route path={route.path} element={<Page />} />
                  </Route>
                );
              })}
            </Route>
          
          </Routes>
      </Router>
	);
};

export default App;